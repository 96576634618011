import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const WordpressLightbox = ({ children }) => {
    const lightbox = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [images, setImages] = useState([]);
    
    const betweenImagesLength = (value) => {
        if (value < 0) {
            return value + images.length;
        }

        if (value > images.length - 1) {
            return value - images.length;
        }

        return value;
    };

    const changeImage = (index) => () => {
        setActiveIndex(betweenImagesLength(index));
    };

    const closeLightbox = () => {
        setActiveIndex(0);
        setImages([]);
    };

    useEffect(() => {
        const galleries = lightbox.current.querySelectorAll(':scope .gallery');

        if (!galleries.length) {
            return;
        }

        const openLightbox = (event) => {
            event.preventDefault();

            const links = [...event.currentTarget.querySelectorAll(':scope .gallery-item a')].map(link => link.href);
            const activeLink = event.target.closest('.gallery-item a') || event.target.querySelector('.gallery-item a');

            setImages(links);
            setActiveIndex(links.findIndex((href) => activeLink.href === href));
        };

        for (const gallery of galleries) {
            gallery.addEventListener('click', openLightbox, {
                capture: true,
            });
        }

        return () => {
            for (const gallery of galleries) {
                gallery.removeEventListener('click', openLightbox, {
                    capture: true,
                });
            }
        }
    }, []);

    return (
        <div ref={lightbox} className="gwp-lightbox-wrapper">
            {children}
            
            {images.length > 0 && (
                <>
                    <button onClick={closeLightbox} className="gwp-lightbox-close" aria-label="Zamknij galerię zdjęć"></button>

                    <div className="gwp-lightbox">
                        <button onClick={closeLightbox} className="gwp-lightbox-close-btn" aria-label="Zamknij galerię zdjęć">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        
                        <div className="gwp-lightbox-content">
                            <div style={{ position: 'relative' }}>
                                <button
                                    className="gwp-lightbox-prev-btn"
                                    onClick={changeImage(activeIndex - 1)} 
                                    aria-label="Poprzednie zdjęcie"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                                
                                <img className="gwp-lightbox-active-image" src={images[activeIndex]} alt="" />
                                
                                <button 
                                    className="gwp-lightbox-next-btn"
                                    onClick={changeImage(activeIndex + 1)} 
                                    aria-label="Następne zdjęcie"
                                >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>

                            <div className="gwp-lightbox-images">
                                {images.length > 10 && Array.from({ length: 5 }).map((_, loopIndex) => (
                                    <img 
                                        key={loopIndex}
                                        onClick={changeImage(activeIndex + loopIndex + 1)}
                                        className="gwp-lightbox-image" 
                                        src={images[betweenImagesLength(activeIndex + loopIndex + 1)]} 
                                        alt="" 
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </>
                
            )}
        </div>
    );
};

export default WordpressLightbox;