import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from 'gatsby-image';
import bootstrap from "../components/bootstrap.module.css"
import FormatDate from "../utils/format-date.js"
import Tags from "../components/tags.js"
import SEO from "../components/seo"
import Share from "../components/share"
import PostContent from "../components/post-content"
import WordpressLightbox from "../components/wordpress-lightbox";

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    
    const { pageContext, data } = props

    this.row = data.mysqlPost
    this.extraTags = data.tags.nodes
    this.seo = data.seo
    this.data = data

    this.schema = {
      "@context": "https://schema.org",
      '@type': (new Date()-Date.parse(this.row.post_date))/1000/24/60/60 > 7 ? 'Article' : 'NewsArticle',
      'name': this.row.post_title,
      'datePublished': this.row.post_date,
      'dateModified': this.row.post_date,
      'headline': this.row.postterms.length > 0 ? this.row.postterms[0].name : 'Fintek',
      'image': this.row.mysqlImage ? 'https://fintek.pl' + this.row.mysqlImage.publicURL : null,
      'author': { '@type': 'Thing', 'name': this.row.author.display_name },
      'publisher': { '@type': 'Organization', 'name': 'Fintek.pl', 'logo': { '@type': 'ImageObject', 'url': 'https://static.fintek.pl/themes/fintekNew/assets/img/logo.png' } },
      'mainEntityOfPage': { '@type': 'WebPage', '@id': 'https://fintek.pl/', 'url': 'https://fintek.pl/', 'name': 'Fintek.pl' },
    }

    this.mainCategory = pageContext.mainCategory ? parseInt(pageContext.mainCategory) : 0
  }  

  getPostMeta(node, key) {
    if(!node.postmetas) return '';

    let value = ''

    node.postmetas.map((object, i) => {
      if(object.meta_key === key) value = object.meta_value
      return true;
    })

    return value
  }

  render() {
    const node = this.data.mysqlPost;

    return (
      <Layout tags={this.row.postterms} extraTags={this.extraTags} className={bootstrap.bgWhite}>
          <SEO 
            title={this.seo && this.seo.title ? this.seo.title : this.row.post_title }
            canonical={this.seo.is_robots_noindex ? null : (this.seo && this.seo.canonical ? this.seo.canonical : 'https://fintek.pl/' + this.row.post_name + '/') } 
            description={this.seo ? this.seo.description : this.row.post_excerpt} 
            schema={this.schema} 
            robots={[(this.seo.is_robots_noindex || this.row.nofollow === 1 ? 'noindex':'index'),(this.seo.is_robots_nofollow?'nofollow':'follow')].join(', ')} 
            image={this.row.mysqlImage ? 'https://fintek.pl' + this.row.mysqlImage.publicURL : null} 
          />  

          <div className={bootstrap.overflowHidden + ' post-reader ' + bootstrap.mb5} id={`post-`+node.post_name}>
            <h1 className={bootstrap.mb3}>{ node.post_title }</h1>

            <p className={[bootstrap.my4,'post-excerpt'].join(' ')}>{node.post_excerpt}</p>

            <Share url={"https://fintek.pl/"+node.post_name+'/'} text={node.post_title} />
            <div className={bootstrap.small + ' meta ' + bootstrap.mb2}>
              {node.author.mysqlImage && 
              <div className={bootstrap.floatLeft + ' meta-image ' + bootstrap.mr3}>
                <Img fluid={node.author.mysqlImage.childImageSharp.fluid} />
              </div>
              }
              <div className={bootstrap.pt1}>
                <strong className={bootstrap.mr3}><AniLink fade className={bootstrap.textDark} to={"/redaktor/"+node.author.user_nicename+"/"}>{node.author.display_name}</AniLink></strong>
                <Tags tags={node.postterms} />
              </div>
              <div>Opublikowano {FormatDate(node.post_date)}</div>
            </div>

            {node.mysqlImage && this.getPostMeta(node, 'wpcf-bez-obrazka') !== '1' ? <Img fluid={node.mysqlImage.childImageSharp.fluid} className={bootstrap.my4} /> : ''}
            
            <WordpressLightbox>
              <PostContent content={node.post_content} />
            </WordpressLightbox>
          </div>

      </Layout>
    )
  }
}

export const query = graphql`
query PostQuery($slug: String, $id: Int) { 
  mysqlPost: mysqlPost(post_name: {eq: $slug}) {
    id
    ID
    post_date
    post_modified
    lead
    post_content
    post_title
    post_excerpt
    post_name
    nofollow
    postterms {
      term_id
      name
      slug
      parent_slug
    }
    author {
      display_name
      user_nicename
      mysqlImage {
        childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    }
    postmetas {
      meta_key
      meta_value
    }
    mysqlImage {
      publicURL
	    childImageSharp {
        fluid(maxWidth: 1300, maxHeight: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  tags: allMysqlTerm {
    nodes {
      slug
      name
      parent_slug
    }
  }
  seo: mysqlIndexable(object_type: {eq: "post"}, object_id: {eq: $id}) {
    title
    description
    object_id
    object_type
    permalink
    breadcrumb_title
    canonical
    is_robots_noindex
    is_robots_nofollow
  }
}
`

